<template>
  <v-btn
    class="white--text sourcery__has-icon"
    color="blue"
    elevation="0"
    @click="redirectToYoutube">
    <img
      src="@/assets/icons/question-icon.svg"
      height="30"
      width="30">
    Help Desk
  </v-btn>
</template>
<script>
import { HELP_CENTER } from '@/constants/urls';
export default {
  name: 'AppHeaderFeedbackAndTraining',
  methods: {
    redirectToYoutube() {
      window.open(HELP_CENTER, '_blank');
    },
  },
};
</script>
